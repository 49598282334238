<template>
  <div class="duel-exercise">
    <div class="duel-bib">
      {{participation.bib}}
    </div>
    <div class="duel-names">
      <strong>{{ getName() }}</strong><br/>
      {{this.club.name}}
    </div>
    <div class="duel-status">
      {{ getStatus() }}
      <span v-if="result">({{result}})</span>
    </div>
  </div>
</template>

<script>
  import find from "lodash/find"

  import numeral from 'numeral'

  import _member from "@/lib/member"
  import _participant from "@/lib/participant"

  export default {
    name: "DuelExercise",
    props: ["exerciseId", "duel"],
    data: function() {
      return {
        participation: undefined,
        participant: undefined,
        club: undefined,
        category: undefined,
      }
    },
    computed: {
      exercise: function() {
        return this.$store.state.exercises.items.find(e => e.id === this.exerciseId)
      },
      result: function() {
        if (! this.duel) return 0

        if (this.duel.winner === this.exerciseId) return this.duel.score

        return 0
      }
    },
    watch:{
      exerciseId: {
        immediate: true,
        handler: function() {
          this.participation = find(this.$store.state.participations.items, i => i.id === this.exercise.participationId)
          if (this.participation) {
            this.participant = _participant.getParticipant(this.participation)
            this.club = _participant.getClub(this.participant)
            this.category = _participant.getCategory(this.participation)
          }
        },
      },
    },
    methods: {
      getName: function() {
        return _member.getName(this.participant)
      },
      getStatus: function() {
        let status = ''
        if (this.exercise.status === 'finished') {
          status = numeral(this.exercise.summary.total).format('0.000')
        }
        else {
          status = this.$t('exercise.status.' + this.exercise.status)
        }
        return status
      },
    },
  }
</script>

<style scoped>
  .duel-exercise {
    display: flex;
    flex-direction: row;
    flex-align: base;
    justify-content: space-between;
    gap: 0.5em;
  }

  .duel-bib {
    flex-grow: 0;
    width: 40px;
    text-align: right;
  }

  .duel-status {
    flex-grow: 0;
    width: 60px;
    text-align: right;
  }

  .duel-names {
    flex-grow: 1;
  }
</style>
