<template>
  <div class="o-layout">
    <div class="o-layout__item">
      <h3 class="c-title c-title--page-section">
        {{$t('exercise.type.' + exerciseTypeId)}} - {{$t('panel')}} {{ panel.index+1 }}
      </h3>

    </div>
    <div class="o-layout__item" style="height: 272px">
      <duel v-for="d of duels" :key="d.id" :duel="d" />
    </div>
    <div class="o-layout__item" style="min-height: 135px">
      <h4 class="c-title c-title--page-section">Add Duel</h4>
      <duel :duel="newDuel" @clearDuel="clearDuel()" @saveDuel="saveDuel()" />
    </div>
    <div v-if="panel.exerciseId" class="o-layout__item">
      <div class="o-layout" style="min-height: 65px">
        <div class="o-layout__item u-1-of-1">
          <strong>Current Panel Exercise</strong>
        </div>
        <div class="o-layout__item u-7-of-8">
          <duelExercise :exerciseId="panel.exerciseId"/>
        </div>
        <div class="o-layout__item u-1-of-8">
          <template>
            <sce-button icon="svg-symbols.svg#plus" v-on:click="addExercise(panel.exerciseId)"
                        class="c-button--secondary c-button--tiny" />
          </template>
        </div>
      </div>
    </div>
    <div v-if="panel.previousExerciseId" class="o-layout__item">
      <div class="o-layout" style="min-height: 65px">
        <div class="o-layout__item u-1-of-1">
          <strong>Previous Panel Exercise</strong>
        </div>
        <div class="o-layout__item u-7-of-8">
          <duelExercise :exerciseId="panel.previousExerciseId"/>
        </div>
        <div class="o-layout__item u-1-of-8">
          <template>
            <sceButton icon="svg-symbols.svg#plus" v-on:click="addExercise(panel.previousExerciseId)"
                        class="c-button--secondary c-button--tiny" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sortBy from 'lodash/sortBy'

  export default {
    name: "session-panel",
    components: {
      duel: require('./duel.vue').default,
      duelExercise: require('./duelExercise.vue').default,
    },
    props: ["exerciseTypeId", "session", "rotation"],
    data: function() {
      return {
        newDuel: {
          sessionId: this.session.id,
          rotation: this.rotation,
          exerciseTypeId: this.exerciseTypeId,
          exercises: [],
          index: 0
        }
      }
    },
    computed: {
      panel: function() {
        return this.$store.state.panels.items.find(p => p.exerciseTypeId === this.exerciseTypeId)
      },
      duels: function() {
        return sortBy(this.$store.state.duels.items.filter(d =>
          d.sessionId === this.session.id && d.rotation === this.rotation && d.exerciseTypeId === this.exerciseTypeId),
          'index')
      },
    },
    methods: {
      addExercise: function(exerciseId) {
        if (this.newDuel.exercises.legnth >= 2 || this.newDuel.exercises.includes(exerciseId)) {
          return
        }
        this.newDuel.exercises.push(exerciseId)
      },
      clearDuel: function() {
        this.newDuel.exercises = []
      },
      saveDuel: function() {
        this.newDuel.index = this.duels.reduce((i, d) => Math.max(d.index, i), -1) + 1
        this.$store.dispatch('duel.add', { data: this.newDuel }).then(() => {
          this.newDuel.exercises = []
        })
      },
    },
  }
</script>

<style scoped>
</style>
