<template>
  <div class="o-layout u-margin-bottom-small" style="border-bottom: 1px solid black ">
    <div class="o-layout__item u-7-of-8">
      <duelExercise v-for="exerciseId of duel.exercises" :key="exerciseId" :exerciseId="exerciseId" :duel="duel"/>
    </div>
    <div class="o-layout__item u-1-of-8"
         style="display: flex; flex-direction: column; justify-content: start; align-items: stretch; padding: 0;">
      <template v-if="duel.id">
        <sceButton label="Show" v-on:click="displayDuel()" class="c-button--primary c-button--tiny" />
        <sceButton icon="svg-symbols.svg#close" v-on:click="deleteDuel()" class="c-button--tertiary c-button--tiny"/>
      </template>
      <template v-else>
        <sceButton label="save" v-on:click="saveDuel()" class="c-button--primary c-button--tiny" />
        <sceButton label="clear" v-on:click="clearDuel()" class="c-button--tertiary c-button--tiny" />
      </template>
    </div>
  </div>
</template>

<script>
  import config from 'client/config';

  export default {
    name: "Duel",
    components: {
      duelExercise: require('./duelExercise.vue').default
    },
    props: ["duel"],
    data: function() {
      return {
      }
    },
    computed: {
    },
    methods: {
      deleteDuel: function() {
        this.$store.dispatch('duel.remove', { duelId: this.duel.id })
      },
      displayDuel: function() {
        this.$http.post(config.root + '/duels/' + this.duel.id + '/publish')
      },
      clearDuel: function() {
        this.$emit('clearDuel')
      },
      saveDuel: function() {
        this.$emit('saveDuel')
      }
    },
  }
</script>
