<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <header class="c-header o-wrapper o-wrapper--huge js-header" :class="{'is-shrinked': shrinked}">
      <router-link :to="{ name: 'supervisor.sessions'}" class="c-logo__link">
        <div class="c-logo"></div>
      </router-link>
      <h1 class="c-header__title u-push-1-of-5-at-medium u-3-of-4-at-medium"
          :title="headerTitle()">{{headerTitle()}}</h1>

      <breadcrump/>
    </header>
    <main class="o-row">
      <div class="c-row" v-for="ed in eventDisciplines" :key="ed.id">
        <h3 class="c-title c-title--page u-push-1-of-5 u-4-of-5 u-padding-left-small">{{ed.name}}</h3>

        <div v-for="session in getSessions(ed.id)" :key="session.id" class="o-layout u-margin-bottom-medium">
          <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny
                  u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
            {{ session.name }}
          </h4>
          <div class="c-button--group c-button--group__huge o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny
                  u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
            <router-link v-for="r in session.rotations" :key="'rot-' + r"
                         class="c-button c-button--huge c-button--primary"
                         :to="{ name: 'duels.session', params: { sessionId: session.id, rotation: r-1 }}">
              {{$t('rotation')}} {{r}}
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import sortBy from 'lodash/sortBy';
  import store from "@/store";

  export default {
    name: "supervisor-sessions",
    components: {
      breadcrump: require('./breadcrump.vue').default,
    },
    computed: {
      eventDisciplines: function () {
        return filter(this.$store.state.eventDisciplines.items, ed => this.hasSessions(ed.id));
      },
      sessions: function () {
        const tmp = filter(this.$store.state.sessions.items, function (session) {
          return session.status === 'active'
        });
        return sortBy(tmp, 'index');
      },
      event: function() {
        return store.state.events.current
      },
      user: function () {
        return this.$store.state.user.instance;
      }
    },
    methods: {
      hasSessions: function(edId) {
        return this.getSessions(edId).length > 0;
      },
      getSessions: function (edId) {
        return filter(this.sessions, item => {
          return item.eventDisciplineId === edId;
        });
      },
      headerTitle: function() {
        return this.event ? this.event.title : this.user.name ? this.user.name : this.user.username;
      }
    }
  }
</script>

<style scoped>

</style>
