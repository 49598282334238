<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <div class="c-wrapper--min-100vh">
      <header class="c-header o-wrapper o-wrapper--huge js-header is-shrinked" >
        <router-link :to="{ name: 'duels.sessions'}" class="c-logo__link">
          <div class="c-logo"></div>
        </router-link>
        <h1 class="c-header__title u-push-1-of-5-at-medium u-3-of-4-at-medium">
          {{session.name}} {{$t('rotation')}} {{rotation + 1}}
          <router-link v-if="rotation > 0" :to="{ name: 'duels.session', params: { rotation: rotation-1 }}">
            &lt; {{$t('rotation')}} {{rotation}}
          </router-link>
          &nbsp; - &nbsp;
          <router-link v-if="rotation < session.rotations -1" :to="{ name: 'duels.session', params: { rotation: rotation+1 }}">
            {{$t('rotation')}} {{rotation + 2}} &gt;
          </router-link>
        </h1>
      </header>
      <main class="c-main-wrapper o-layout o-layout--main">
        <div class="c-main o-layout__item">
          <div class="o-layout">
            <div v-for="exerciseTypeId of exerciseTypes" :key="exerciseTypeId"
                 class="o-layout__item u-1-of-3 u-margin-bottom-medium">
              <sessionPanel :exerciseTypeId="exerciseTypeId" :session="session" :rotation="rotation" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find'

  import sessionLib from '@/lib/session'

  export default {
    name: "duel-session",
    components: {
      sessionPanel: require('./SessionPanel.vue').default,
    },
    data: function() {
      return {
        exerciseTypes: []
      }
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      session: function() {
        return find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
      },
      rotation: function() {
        return parseInt(this.$route.params.rotation)
      },
      panels: function() {
        return this.$store.state.panels.items
      }
    },
    watch: {
      session: {
        immediate: true,
        handler: function() {
          this.init()
        }
      },
      rotation: function() {
        this.init()
      }
    },
    methods: {
      init: function() {
        this.exerciseTypes = sessionLib.getSessionExerciseTypes(this.session.id)
      },
    },
  }
</script>

<style scoped>
.c-header {
  padding: 15px 20px 15px;
  min-height: 60px;
}

.c-logo__link {
  top: 16px;
}

.c-header + * {
  margin-top: 60px;
}

.c-main-wrapper {
  min-height: calc(100vh - 90px)
}
</style>
